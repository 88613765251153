import React from "react";
import PropTypes from "prop-types";
import { Breadcrumb as BreadcrumbBootstrap } from "react-bootstrap";
import "./Breadcrumb.scss";

export const linkTo = (path) => {
  return `${window.location.protocol}$${window.location.host}${path}`;
};

function Breadcrumb(props) {
  const { Item } = BreadcrumbBootstrap;

  return (
    <BreadcrumbBootstrap>
      {props.items.map((item, index) => {
        return (
          <Item href={item.link} key={index} active={item.active}>
            {item.name}
          </Item>
        );
      })}
    </BreadcrumbBootstrap>
  );
}

Breadcrumb.propTypes = {
  items: PropTypes.array,
};

export default Breadcrumb;
