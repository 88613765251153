import React from "react";
import PropTypes from "prop-types";
import { getIndexPath, getMetaForId } from "../../utils/utils";
import "./Presentation.scss";
// import { Container, Row } from "react-bootstrap";
import Fullscreen from "../../components/Fullscreen/Fullscreen";
import { useHistory, Link } from "react-router-dom";
import Breadcrumb, { linkTo } from "../../components/Breadcrumb/Breadcrumb";

import FullscreenImage from "../../assets/add.svg";

export function IFrame({ path }) {
  return (
    <iframe
      frameBorder="0"
      allowFullScreen
      width={"100%"}
      title="presentation"
      height={"100%"}
      className="presentation-frame"
      src={path}
    />
  );
}

IFrame.propTypes = {
  path: PropTypes.string.isRequired,
};

function Presentation({
  match: {
    params: { id, screen },
  },
}) {
  const FULLSCREEN = screen === "fullscreen";
  const router = useHistory();

  const INDEX_PATH = getIndexPath(id);
  const meta = getMetaForId(id);

  if (!INDEX_PATH || !meta) {
    router.push("/404");
  }

  function closeFunction(event) {
    event.preventDefault();
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
    }
    router.push(`/presentation/${id}`);
  }

  if (meta.presentation) {
    return (
      <div className="w-100">
        <Fullscreen
          closeFunction={closeFunction}
          open={FULLSCREEN}
          path={INDEX_PATH}
        />
        <Breadcrumb
          items={[
            { name: "Home", active: false, link: "https://intl.vyaire.com/" },
            { name: "Presentations", active: false, link: "/" },
            {
              name: meta.presentation.name,
              active: true,
              link: linkTo(`/presentation/${id}`),
            },
          ]}
        />
        <div className="outer-box">
          <h2 className="presentation-header">{meta.presentation.name}</h2>
          <div className="presentation-container">
            <IFrame path={INDEX_PATH} />
          </div>
          <div className="actions">
            <Link to={`/presentation/${id}/fullscreen`}>
              <img alt="fullscreen mode" src={FullscreenImage} className="icon" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

Presentation.propTypes = {};

export default Presentation;
