import React from "react";
// import PropTypes from "prop-types";
import Cookies from "js-cookie";
import CheckProfessionComponent from "../../components/CheckProfession/CheckProfessionComponent";

const PROF_CHECK_KEY = "profession_check";
function CheckProfession({ children }) {
  const hasChecked = !!Cookies.get(PROF_CHECK_KEY);

  const [approval, setapproval] = React.useState(hasChecked);
  function approve() {
    Cookies.set(PROF_CHECK_KEY, true);
    setapproval(true);
  }
  function decline() {
    Cookies.remove(PROF_CHECK_KEY);
    setapproval(false);
  }
  if (!approval) {
    return <CheckProfessionComponent approve={approve} decline={decline} />;
  } else {
    return children;
  }
}

CheckProfession.propTypes = {};

export default CheckProfession;
