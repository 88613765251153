import React from "react";
import PropTypes from "prop-types";
import "./Fullscreen.scss";
import { IFrame } from "../../pages/Presentation/Presentation";

import CloseButtonSvg from "../../assets/PWA_Close_Button_48pxX48px.svg";

function Fullscreen({ open, path, closeFunction }) {
  if (!open) return <></>;

  return (
    <div className="fullscreen-container">
      <img
        src={CloseButtonSvg}
        width={42}
        alt="close-button"
        onClick={closeFunction}
        className="close-button"
      />
      <div className="outer-box">
        <div className="fullscreen-box">
          <div className="iframe">
            <IFrame path={path} />
          </div>
        </div>
      </div>
    </div>
  );
}

Fullscreen.propTypes = {
  open: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  closeFunction: PropTypes.func.isRequired,
};

export default Fullscreen;
