const {library} = require('../library/library.json');


export function getIdForPresentationName(name) {
  return name.replace(" ", "_").toLowerCase();
}

export function getPathForId(id) {
  return `${process.env.PUBLIC_URL}/assets/${id}`;
}

export function getIndexPath(id) {
  return `${getPathForId(id)}/presentation/index.html`
}

export function getThumbnailPathForId(id) {
  return `${getPathForId(id)}/thumbnail.jpg`;
}


export function getMetaForId(id) {
  const {assets} = library;
  return assets.find(i => i.id === id);
}