import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home/Home";
import Presentation from "./pages/Presentation/Presentation";

export default function Router() {
  return (
    <Switch>
      <Route path="/presentation/hygiene">
        <Redirect to="/presentation/hygiene_15" />
      </Route>
      <Route path="/presentation/:id/:screen?" component={Presentation} />
      <Route exact path="/" component={Home} />
      <Route path="*" component={Home} />
    </Switch>
  );
}
