import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import "./Home.scss";

import PresentationBox from "../../components/PresentationBox/PresentationBox";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { LanguageContext } from "../../utils/language";

const LIBRARY = require("../../library/library.json");

export default function Home() {
  const language = React.useContext(LanguageContext);

  const {
    library: { assets },
  } = LIBRARY;
  return (
    <Container className="home-container" fluid>
      <Row>
        <Breadcrumb
          items={[
            { name: "Home", active: false, link: "https://intl.vyaire.com/" },
            { name: "Presentations", active: true, link: "#" },
          ]}
        />
      </Row>
      <Row>
        <h1 className="header">PRESENTATIONS</h1>
      </Row>
      <Row className="lib-row">
        {assets
          .filter((x) => language.current.value.indexOf(x.language) >= 0)
          .map((asset, index) => {
            // Check type and make factory
            switch (asset.type) {
              case "presentation":
                return (
                  <Col
                    xs={12}
                    sm={12}
                    md={3}
                    className="presentation-col"
                    key={index}
                  >
                    <PresentationBox
                      id={asset.id}
                      name={asset.presentation.name}
                    />
                  </Col>
                );

              default:
                return <></>;
            }
          })}
      </Row>
    </Container>
  );
}
