import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./PresentationBox.scss";
import { getThumbnailPathForId } from "../../utils/utils";
function PresentationBox({ name, id }) {
  return (
    <Link to={{ pathname: `/presentation/${id}` }}>
      <Card className="presentation-box">
        <Card.Img
          className="presentation-image"
          variant="top"
          src={getThumbnailPathForId(id)}
        ></Card.Img>
        <Card.Body>
          <Card.Title className="presentation-headline">{name}</Card.Title>
        </Card.Body>
      </Card>
    </Link>
  );
}

PresentationBox.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default PresentationBox;
