import React from "react";
// import PropTypes from "prop-types";
import Navigation from "../../components/Navigation/Navigation";
import { Container, Row } from "react-bootstrap";
import "./Layout.scss";
import CheckProfession from "../CheckProfession/CheckProfession";
import Footer from "../../components/Footer/Footer";

function Layout({ children }) {
  return (
    <main>
      <Navigation />
      <Container className="main-container" fluid>
        <Row className="main-row">
          <CheckProfession>{children}</CheckProfession>
          <Footer />
        </Row>
      </Container>
    </main>
  );
}

Layout.propTypes = {};

export default Layout;
