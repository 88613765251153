import React from "react";
// import PropTypes from 'prop-types'
import "./Footer.scss";

function Footer(props) {
  return (
    <div className="footer">
      <a rel="noopener noreferrer" target="_blank" href="https://intl.vyaire.com/privacy-policy">
        Privacy Policy
      </a>{" "}
      |{" "}
      <a rel="noopener noreferrer" target="_blank" href="https://intl.vyaire.com/terms-of-use">
        Terms and Conditions of Use
      </a>
    </div>
  );
}

Footer.propTypes = {};

export default Footer;
