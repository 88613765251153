import React from "react";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import { history } from "./history";
import Router from "./router";
import LanguageProvider from "./utils/language";
import Layout from "./utils/Layout/Layout";

function App() {
  return (
    <BrowserRouter history={history}>
      <LanguageProvider>
        <Layout>
          <Router />
        </Layout>
      </LanguageProvider>
    </BrowserRouter>
  );
}

export default App;
