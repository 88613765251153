import React from "react";
// import PropTypes from "prop-types";
import "./Navigation.scss";

import { MdCollections } from "react-icons/md";

import { Navbar, NavDropdown } from "react-bootstrap";
import { LanguageContext } from "../../utils/language";
import Logo from "./logo.svg";

function Navigation(props) {
  const { current, setLanguage, languages, findLanguage } = React.useContext(
    LanguageContext
  );

  return (
    <Navbar bg="light" variant="light">
      <Navbar.Brand target="_blank" href="https://intl.vyaire.com/">
        <img alt="" src={Logo} className="d-inline-block logo align-top" />
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        <a href={"/"}>
          <MdCollections />
        </a>
        <NavDropdown
          onSelect={(e) => setLanguage(findLanguage(e))}
          title={current.public}
        >
          {languages.map((language) => {
            return (
              <NavDropdown.Item
                key={language.value}
                value={language}
                eventKey={language.value}
                active={language.active}
                disabled={!language.active}
              >
                {language.extended}
              </NavDropdown.Item>
            );
          })}
        </NavDropdown>
      </Navbar.Collapse>
    </Navbar>
  );
}

Navigation.propTypes = {};

export default Navigation;
