import React from "react";
import PropTypes from "prop-types";
import "./CheckProfessionComponent.scss";
import { Button } from "react-bootstrap";

function CheckProfessionComponent(props) {
  return (
    <div className="mid-box">
      <h1>Healthcare Professional</h1>
      <p>
        The information provided in this site is intended for healthcare
        professionals. If you are a healthcare professional, please click "YES"
        to continue. If not, click "NO" to exit.
      </p>
      <div className="buttons">
        <Button onClick={props.approve}>Yes</Button>
        <Button onClick={props.decline}>No</Button>
      </div>
    </div>
  );
}

CheckProfessionComponent.propTypes = {
  approve: PropTypes.func.isRequired,
  decline: PropTypes.func.isRequired,
};

export default CheckProfessionComponent;
