import React from "react";
export const LANGUAGES = [
  {
    value: ['de', 'de-de'],
    public: "DE",
    extended: "Deutsch",
    active: false,
  },
  {
    value: ['en-us', 'en', 'en-en'],
    public: "EN",
    extended: "English",
    active: true,
  },
];

const LANGUAGE_KEY = "lang";
function getLocaleLanguage() {
  const language = localStorage.getItem(LANGUAGE_KEY);

  return JSON.parse(language);
}

function saveLanguage(lang) {
  localStorage.setItem(LANGUAGE_KEY, JSON.stringify(lang));
}

export const LanguageContext = React.createContext({
  current: getLocaleLanguage() || LANGUAGES[1],
  setLanguage: () => {},
  findLanguage: (value) => {},
  languages: LANGUAGES,
});

export default function LanguageProvider({ children }) {
  const [language, setLanguage] = React.useState(
    getLocaleLanguage() || LANGUAGES[1]
  );

  function _setLanguage(language) {
    if (language) {
      setLanguage(language);
      saveLanguage(language);
    }
  }

  

  return (
    <LanguageContext.Provider
      value={{
        current: language,
        setLanguage: _setLanguage,
        languages: LANGUAGES,
        findLanguage: (value) => LANGUAGES.find((e) => e.value === value),
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
}
